import { useEffect, useState } from "react";
import api from "@/helpers/api";
import error from "./error";
import { MatterType } from "./types";

type PropsType<T = MatterType> = {
  initialMatters?: T[];
  params?: { [key: string]: unknown };
  endpoint?: string;
  initialPage?: number;
};

type ReturnType<T = MatterType> = [
  { matters: T[]; loading: boolean; allLoaded: boolean },
  (reset: boolean) => void
];

export default function useMatters<T = MatterType>({
  initialMatters = [],
  params = {},
  endpoint = "matters",
  initialPage = 2,
}: PropsType<T>): ReturnType<T> {
  const [matters, setMatters] = useState(initialMatters);
  const [allLoaded, setAllLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(initialPage);
  const [key, setKey] = useState(1);

  useEffect(() => {
    if (loading || allLoaded || key >= 1) return;
    setLoading(true);
    void doFetch();

    async function doFetch() {
      try {
        // response could be [...] or {matters: [...]}
        const { data } = await api.get<T[] | { matters: T[] }>(endpoint, {
          params: { ...params, page },
        });
        const items = "matters" in data ? data.matters : data;
        setMatters(matters.concat(items));
        setLoading(false);
        setPage(page + 1);
        if (!items.length) {
          setAllLoaded(true);
        }
      } catch (e) {
        error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  function getMatters(reset = false) {
    if (reset) {
      setMatters([]);
      setAllLoaded(false);
      setLoading(false);
      setPage(1);
    }
    setKey(Math.random());
  }

  return [{ matters, loading, allLoaded }, getMatters];
}
